import { PaymentProvider } from '@/src/services/types/payment'
import storage from 'local-storage-fallback'
export type TokenActionType = 'loginAction' | 'depositLoginAction' | null

const setAuthToken = (token: string, action: TokenActionType) => {
  if (!token || !action) return null
  storage.setItem('token', token)
  storage.setItem('action', action)
}

const getAuthToken = () => {
  if (typeof window === 'undefined') return null
  return storage.getItem('token') || ''
}

const setDepositReference = (reference: string) => {
  if (!reference) return null
  storage.setItem('reference', reference)
}

const getDepositReference = () => {
  if (typeof window === 'undefined') return null
  return storage.getItem('reference') || ''
}

const clearDepositReference = () => {
  storage.removeItem('reference')
}

const getAuthUrl = () => {
  if (typeof window === 'undefined') return null
  return storage.getItem('authUrl') || ''
}

const getAuthAction = () => {
  if (typeof window === 'undefined') return null
  return storage.getItem('action') as TokenActionType
}

const clearAuthData = () => {
  storage.removeItem('token')
  storage.removeItem('action')
  storage.removeItem('x-sessionId')
}

const setFeSessionIdToken = (session: string) => {
  if (typeof window === 'undefined') return null
  storage.setItem('x-feSessionId', session)
}

const clearFeSessionIdToken = () => {
  storage.removeItem('x-feSessionId')
}

const getFeSessionIdToken = () => {
  if (typeof window === 'undefined') return null
  return storage.getItem('x-feSessionId')
}

const setFeProfileSessionIdToken = (session: string) => {
  if (typeof window === 'undefined') return null
  storage.setItem('x-feProfileSessionId', session)
}

const clearFeProfileSessionIdToken = () => {
  storage.removeItem('x-feProfileSessionId')
}

const getFeProfileSessionIdToken = () => {
  if (typeof window === 'undefined') return null
  return storage.getItem('x-feProfileSessionId')
}

const setAvailableSessionToken = (session: string) => {
  if (typeof window === 'undefined') return null
  return storage.setItem('x-sessionId', session)
}

const getAvailableSessionToken = () => {
  if (typeof window === 'undefined') return null
  return storage.getItem('x-sessionId')
}

const setHideRGI = () => {
  storage.setItem('hideRGI', 'true')
}

const getHideRGI = () => {
  if (typeof window === 'undefined') return null
  return storage.getItem('hideRGI')
}

const clearHideRGI = () => {
  storage.removeItem('hideRGI')
}

const getAffiliateToken = () => {
  if (typeof window === 'undefined') return null
  return storage.getItem('AffiliateToken') || null
}

const setAffiliateToken = (token: string) => {
  storage.setItem('AffiliateToken', token || '')
}

const getAffiliateId = () => {
  if (typeof window === 'undefined') return null
  return storage.getItem('AffiliateId') || null
}

const setAffiliateId = (id: string) => {
  storage.setItem('AffiliateId', id || '')
}

const setLastVisitedPage = (pathname: string | null) => {
  if (!pathname) return storage.removeItem('lvp')
  storage.setItem('lvp', pathname)
}

const setLastVisitedLobby = (pathname: string) => {
  storage.setItem('lvl', pathname)
}

const getLastVisitedLobby = () => {
  return storage.getItem('lvl') || '/'
}

const getLastVisitedPage = () => {
  const lvp = storage.getItem('lvp')
  storage.removeItem('lvp')
  return lvp
}

const getReturnToPage = () => {
  const lvpRESUME = storage.getItem('lvpRESUME')
  storage.removeItem('lvpRESUME')
  return lvpRESUME
}

const clearAuthUrl = () => {
  storage.removeItem('authUrl')
}

const clearAuthorizationId = () => {
  storage.removeItem('authorizationId')
}

const clearHasOpenedSwish = () => {
  storage.removeItem('hasOpenedSwish')
}

const getAuthorizationId = () => {
  return storage.getItem('authorizationId') || null
}

const getHasOpenedSwish = () => {
  return storage.getItem('hasOpenedSwish') === 'true'
}

const setHasOpenedSwish = () => {
  storage.setItem('hasOpenedSwish', 'true')
}

const setDepositToken = (token: string) => {
  if (!token) return null
  storage.setItem('depositToken', token)
}

const getDepositToken = () => {
  if (typeof window === 'undefined') return null
  return storage.getItem('depositToken') || ''
}

const clearDepositToken = () => {
  storage.removeItem('depositToken')
}

const clearAuthStorage = () => {
  clearAuthorizationId()
  clearDepositReference()
  clearDepositToken()
  clearAuthData()
  clearAuthUrl()
}

const setAuthorizationId = (authorizationId: string) => {
  storage.setItem('authorizationId', authorizationId)
}

const setAuthUrl = (url: string) => {
  if (!url) return null
  storage.setItem('authUrl', url)
}

const getLastUsedPaymentMethod = () => {
  return (storage.getItem('lastUsedPaymentMethod') as PaymentProvider) || null
}

const setLastUsedPaymentMethod = (paymentMethod: PaymentProvider) => {
  storage.setItem('lastUsedPaymentMethod', paymentMethod)
}

const setSwishQr = (qr: string) => {
  if (!qr) return null
  storage.setItem('swishQr', `${qr.replace(/"/g, '')}`)
}

const getShowPill = () => {
  if (typeof window === 'undefined') return null
  return storage.getItem('showPill')
}

const clearShowPill = () => {
  storage.removeItem('showPill')
}

const setInfoModal = (show: string) => {
  storage.setItem('isInfoModalOpen', show)
}

const getInfoModal = () => {
  return storage.getItem('isInfoModalOpen')
}

const clearInfoModal = () => {
  storage.removeItem('isInfoModalOpen')
}

const localStorageService = {
  getShowPill,
  clearShowPill,
  setAuthToken,
  getAuthToken,
  getAuthAction,
  getAuthUrl,
  clearAuthData,
  setFeSessionIdToken,
  clearFeSessionIdToken,
  getFeSessionIdToken,
  setFeProfileSessionIdToken,
  clearFeProfileSessionIdToken,
  getFeProfileSessionIdToken,
  setHideRGI,
  getHideRGI,
  clearHideRGI,
  setDepositReference,
  getDepositReference,
  getDepositToken,
  getHasOpenedSwish,
  clearDepositReference,
  getAffiliateToken,
  setAffiliateToken,
  setHasOpenedSwish,
  getAffiliateId,
  setAffiliateId,
  getInfoModal,
  setInfoModal,
  clearInfoModal,
  setAuthorizationId,
  setLastVisitedPage,
  getLastVisitedPage,
  getAvailableSessionToken,
  setAvailableSessionToken,
  setSwishQr,
}

export {
  clearAuthData,
  clearAuthorizationId,
  clearAuthStorage,
  clearAuthUrl,
  clearDepositReference,
  clearFeProfileSessionIdToken,
  clearFeSessionIdToken,
  clearHasOpenedSwish,
  clearHideRGI,
  clearInfoModal,
  getAffiliateId,
  getAffiliateToken,
  getAuthAction,
  getAuthorizationId,
  getAuthToken,
  getAuthUrl,
  getAvailableSessionToken,
  getDepositReference,
  getDepositToken,
  getFeProfileSessionIdToken,
  getFeSessionIdToken,
  getHasOpenedSwish,
  getHideRGI,
  getInfoModal,
  getLastUsedPaymentMethod,
  getLastVisitedLobby,
  getLastVisitedPage,
  getReturnToPage,
  setAffiliateId,
  setAffiliateToken,
  setAuthorizationId,
  setAuthToken,
  setAuthUrl,
  setDepositReference,
  setDepositToken,
  setFeProfileSessionIdToken,
  setFeSessionIdToken,
  setHasOpenedSwish,
  setHideRGI,
  setInfoModal,
  setLastUsedPaymentMethod,
  setLastVisitedLobby,
  setLastVisitedPage,
  setSwishQr,
}
export default localStorageService
