import { createSlice } from '@reduxjs/toolkit'

type FastTrackState = {
  ftAuthToken: string | undefined
}

const initialState: FastTrackState = {
  ftAuthToken: undefined,
}

export const fastTrackSlice = createSlice({
  name: 'fastTrackAuth',
  initialState,
  reducers: {
    setFTAuthToken: (state, action) => {
      state.ftAuthToken = action.payload
    },
  },
})

export const { setFTAuthToken } = fastTrackSlice.actions

export default fastTrackSlice.reducer
