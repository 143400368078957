import { MissingInfoSteps } from '@/src/app/[lang]/missing-info/[uid]/_types/missing-info-types.ts'
import { AppState } from '@/src/store/store.tsx'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type NavigationState = {
  lastVisitedPage: string | null
  initialUserSteps: MissingInfoSteps | undefined
  showComplete: boolean
  showCompletePayment: boolean
  depositWithBonus: boolean | null
}

const initialState: NavigationState = {
  lastVisitedPage: null,
  initialUserSteps: undefined,
  showComplete: false,
  showCompletePayment: false,
  depositWithBonus: null,
}

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setInitialUserSteps: (
      state,
      action: PayloadAction<MissingInfoSteps | undefined>,
    ) => {
      state.initialUserSteps = action.payload
    },
    setShowComplete: (state, action: PayloadAction<boolean>) => {
      state.showComplete = action.payload
    },
    setShowCompletePayment: (state, action: PayloadAction<boolean>) => {
      state.showCompletePayment = action.payload
    },
    setDepositWithBonus: (state, action: PayloadAction<boolean>) => {
      state.depositWithBonus = action.payload
    },
  },
})

export const {
  setInitialUserSteps,
  setShowComplete,
  setShowCompletePayment,
  setDepositWithBonus,
} = navigationSlice.actions
export const selectInitialUserSteps = (state: AppState) =>
  state.navigation.initialUserSteps
export const selectShowComplete = (state: AppState) =>
  state.navigation.showComplete
export const selectDepositWithBonus = (state: AppState) =>
  state.navigation.depositWithBonus
export default navigationSlice.reducer
