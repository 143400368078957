import {
  LimitsFormattedType,
  LimitsType,
  LimitType,
} from '@/src/services/types/limits.tsx'

type FormDataType = {
  [key: string]: string | number | boolean | Date | null | undefined
}

const allowedTypes = ['string', 'boolean', 'number']
const getFormData = (object: FormDataType) => {
  const formData = new FormData()
  Object.keys(object).forEach((key) => {
    let value = object[key]
    if (
      (allowedTypes.includes(typeof value) || value instanceof Date) &&
      value !== null
    ) {
      if (value instanceof Date) {
        value = value.toISOString()
      }
      if (value !== undefined) formData.append(key, value.toString())
    }
  })
  return formData
}

const mapEmploymentListToSelect = (obj: object, label: 'label' | 'name') => {
  if (obj) {
    const emptyArray: object[] = []
    Object.keys(obj).map((key, index) => {
      emptyArray.push({
        [label]: Object.values(obj)[index] as string,
        value: key,
      })
    })
    return emptyArray
  }
}

const getQueryData = (
  obj: Record<string, string | number | boolean> | undefined,
) => {
  if (!obj || Object.keys(obj).length === 0) return ''

  const _obj: Record<string, string> = {}
  for (const key in obj) {
    _obj[key] = String(obj[key])
  }

  return '?' + new URLSearchParams(_obj).toString()
}

const limitTypes: LimitType[] = [
  'dailyDeposit',
  'weeklyDeposit',
  'monthlyDeposit',
  'loss',
  'wager',
  'oneTime',
  'exclude',
  'timeLimit',
  'autoFlush',
  'realityCheck',
  'dailyTime',
  'weeklyTime',
  'monthlyTime',
  'dailyLossLimitEnable',
  'weeklyLossLimitEnable',
  'monthlyLossLimitEnable',
]

const TRANSFORM_KEY = {
  dailyLossLimitEnable: 'dailyLossLimit',
  weeklyLossLimitEnable: 'weeklyLossLimit',
  monthlyLossLimitEnable: 'monthlyLossLimit',
}

export const closestDepositLimit = (
  limits: LimitsFormattedType | undefined,
) => {
  if (!limits) return
  const { dailyDeposit, weeklyDeposit, monthlyDeposit } = limits
  const possible = [
    { type: 'day', ...dailyDeposit },
    { type: 'week', ...weeklyDeposit },
    { type: 'month', ...monthlyDeposit },
  ].filter((f) => f.amount)

  if (possible.length === 0) return

  const closest = possible?.reduce((previous, current) => {
    // Madness here, BO sends amount or not depending of a limit that might exist or not
    const currentUsedAmount = current?.usedAmount || 0
    const previousUsedAmount = previous?.usedAmount || 0
    const currentAmount = current?.amount || 0
    const previousAmount = previous?.amount || 0
    return currentAmount - currentUsedAmount <
      previousAmount - previousUsedAmount
      ? current
      : previous
  })

  return closest
    ? {
        type: closest.type,
        value: (closest.amount || 0) - (closest.usedAmount || 0),
      }
    : null
}
const formatLimits = (response: LimitsType): LimitsFormattedType => {
  const _limits = {}
  if (!Array.isArray(response)) {
    return response
  }
  limitTypes.forEach((limitType) => {
    const limit =
      Array.isArray(response) &&
      response.filter((responseLimit) => {
        return responseLimit?.hasOwnProperty(limitType)
      })[0]

    if (limit) {
      if (TRANSFORM_KEY.hasOwnProperty(limitType)) {
        // @ts-ignore
        _limits[TRANSFORM_KEY[limitType]] = limit
      } else {
        // @ts-ignore
        _limits[limitType] = limit
      }
    }
  })

  return _limits as LimitsFormattedType
}

export { formatLimits, getFormData, getQueryData, mapEmploymentListToSelect }
