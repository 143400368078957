import {
  api,
  cloudflare,
  cms,
  fastTrack,
  fusionUrl,
  next,
  redTiger,
} from '@/src/services'
import authReducer from '@/src/store/slices/authSlice.tsx'
import configsReducer from '@/src/store/slices/configsSlice.ts'
import fastTrackReducer from '@/src/store/slices/fastTrackSlice.ts'
import featureReducer from '@/src/store/slices/featureToggleSlice.tsx'
import modalReducer from '@/src/store/slices/modalSlice.tsx'
import navigationReducer from '@/src/store/slices/navigationSlice.tsx'
import redirectReducer from '@/src/store/slices/redirectSlice.tsx'
import sportsV2Reducer from '@/src/store/slices/sportsV2Slice.ts'
import uiReducer from '@/src/store/slices/uiSlice.ts'
import { combineReducers } from '@reduxjs/toolkit'

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [cms.reducerPath]: cms.reducer,
  [next.reducerPath]: next.reducer,
  [fastTrack.reducerPath]: fastTrack.reducer,
  [fusionUrl.reducerPath]: fusionUrl.reducer,
  [redTiger.reducerPath]: redTiger.reducer,
  [cloudflare.reducerPath]: cloudflare.reducer,
  auth: authReducer,
  redirect: redirectReducer,
  modal: modalReducer,
  navigation: navigationReducer,
  features: featureReducer,
  fastTrackAuth: fastTrackReducer,
  configs: configsReducer,
  sportsV2: sportsV2Reducer,
  ui: uiReducer,
})

const rootMiddleware = (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(
    api.middleware,
    cms.middleware,
    next.middleware,
    fastTrack.middleware,
    fusionUrl.middleware,
    redTiger.middleware,
    cloudflare.middleware,
  )

export { rootMiddleware, rootReducer }
