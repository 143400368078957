import { AppState } from '@/src/store/store.tsx'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type SliceState = {
  skipState: boolean
}

const initialState: SliceState = {
  skipState: false,
}

export const sportsV2Slice = createSlice({
  name: 'sportsV2',
  initialState,
  reducers: {
    setSkipState: (state, action: PayloadAction<boolean>) => {
      state.skipState = action.payload
    },
  },
})

export const { setSkipState } = sportsV2Slice.actions

export const selectSkipState = (state: AppState) => state.sportsV2.skipState

export default sportsV2Slice.reducer
