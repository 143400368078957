import { AppState } from '@/src/store/store.tsx'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ConfigsState = {
  reloadPaymentIq: boolean
}

const initialState: ConfigsState = {
  reloadPaymentIq: false,
}

export const configSlice = createSlice({
  name: 'configs',
  initialState,
  reducers: {
    setReloadPaymentIq: (state, action: PayloadAction<boolean>) => {
      state.reloadPaymentIq = action.payload
    },
  },
})

export const { setReloadPaymentIq } = configSlice.actions
export const selectReloadPaymentIq = (state: AppState) =>
  state.configs.reloadPaymentIq
export default configSlice.reducer
