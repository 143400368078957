const ServerError = {
  Exception: 100,
  LimitException: 399,
  NoSuchElementException: 101,
  IllegalArgumentException: 102,
  NumberFormatException: 103,
  IllegalStateException: 105,
  InvalidTextException: 204,
  CurrencyExchangeRateNotFoundException: 212,

  // Validation related exceptions
  ValidationException: 104,
  UserRegistrationLimitException: 106,
  DuplicatedAddressException: 170,
  DuplicatedDataException: 180,
  EmailNotFoundException: 181,
  NewAndOldPasswordSameException: 182,
  FieldValidationException: 196,
  RecaptchaVerifyFailedException: 197,
  CounterLimitExceededException: 206,
  PaymentUpdateNotAllowedException: 213,
  PaymentAccountTakenException: 224,
  NotResolvedCountryException: 223,
  LimitFreezeException: 226,

  // Authentication related exceptions
  BadCredentialsException: 107,
  BlacklistedException: 108,
  AuthenticationException: 109,
  UserNotFoundException: 110,
  PaymentDetailNotFoundException: 191,
  ExternalLoginRequiredException: 192,

  // User status related exceptions
  UserFrozenException: 111,
  UserClosedException: 112,
  UserSelfClosedException: 216,
  UserCoolOffClosedException: 217,
  NotActivationException: 114,
  AlreadyActivatedException: 113,
  ActivationCodeExpiredException: 116,
  ActivationCodeNotFoundException: 183,
  TermsNotAcceptedException: 155,
  LicenseNotAcceptedException: 214,
  LicenseAndTermsNotAcceptedException: 215,
  AlreadyVerifiedException: 193,
  VerificationCodeExpiredException: 194,
  VerificationCodeNotFoundException: 195,
  VerificationMessageLimitExceededException: 198,
  EmailNotVerifiedException: 199,
  PhoneNumberNotVerifiedException: 200,
  NotMigratedException: 202,
  AlreadyMigratedException: 207,
  CodeAlreadyUsedException: 209,
  UserNotActiveException: 210,
  GamingBoardException: 211,
  EmailAndPhoneNumberNotVerifiedException: 219,
  EmailNotFilledException: 222,
  ContactUsLimitExceededException: 225,
  UnderAgedException: 226,
  HighDepositLimitAmountExceededException: 227,
  TimeLimitException: 228,

  SelfExclusionCheckException: 236,

  // Session related exceptions
  NoAvailableSessionException: 115,

  // Payment related exceptions
  PaymentLimitException: 117,
  // Added from FE to distinguish min max
  PaymentLimitExceptionDepositMin: 117.1,
  PaymentLimitExceptionDepositMax: 117.2,
  PaymentLimitExceptionWithdrawalMin: 117.3,
  PaymentLimitExceptionWithdrawalMax: 117.4,

  PaymentException: 118,
  PaymentGatewayException: 119,
  NegativeBalanceException: 121,
  VoucherAlreadyUsedException: 218,
  NoPaymentDetailsFilledException: 125,
  PaymentDetailsChangedException: 126,
  WithdrawalBlockedException: 127,
  PaymentNotAllowedException: 129,
  DepositRequiredException: 124,
  PaymentFailedException: 186,
  OpenPaymentNotificationException: 201,
  PaymentMethodNotFoundException: 208,

  // Opening a new game but gaming limit has been reached
  GamingLimitException: 122,
  GameNotFoundException: 131,
  GameDisabledException: 132,
  GameLaunchException: 205,
  NoNickNameException: 203,

  // BGC specific exceptions
  NoBgcTokenException: 133,
  BgcLossCreditLimitException: 134,
  BGCEpisException: 184,
  BGCException: 185,

  NotActiveUserException: 130,
  ExternalConnectionException: 140,

  DocumentUploadException: 160,

  // If real money is disabled
  RealMoneyNotEnabledException: 120,

  UserNotValidatedException: 128,
  BingoHandlingException: 191,

  // Product exception should start with 300
  ProductDisabledException: 3001,

  RebateErrorException: 230,
  MfaCodeRequiredException: 258,
  UserNoDetailsException: 262,
  AccountPendingException: 267,
}

export const CriticalErrors = [
  ServerError.TimeLimitException,
  ServerError.NotActiveUserException,
  ServerError.UserNotFoundException,
  ServerError.BlacklistedException,
  ServerError.UserFrozenException,
  ServerError.UserClosedException,
  ServerError.UserSelfClosedException,
  ServerError.UserCoolOffClosedException,
  ServerError.NotActivationException,
  ServerError.SelfExclusionCheckException,
  ServerError.PaymentLimitException,
  ServerError.PaymentLimitExceptionDepositMin,
  ServerError.PaymentLimitExceptionDepositMax,
  ServerError.PaymentLimitExceptionWithdrawalMin,
  ServerError.PaymentLimitExceptionWithdrawalMax,
  ServerError.PaymentNotAllowedException,
  ServerError.PaymentFailedException,
]

export default ServerError
