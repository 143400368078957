import features from '@/features.json'
import { AppState } from '@/src/store/store.tsx'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface InitialState {
  [key: string]: { enabled: boolean }
}

const envFeatures = process.env.NEXT_PUBLIC_FEATURES?.split(',').map((f) => ({
  [f]: { enabled: true },
}))

const reducedEnvironmentString = envFeatures?.reduce(
  (a, b) => Object.assign(a, b),
  {},
)

const initialState: InitialState = {
  ...features,
  ...reducedEnvironmentString,
}

export const featureToggleSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    enable: (state, action: PayloadAction<string>) => {
      state[action.payload] = { ...state[action.payload], enabled: true }
    },
    disable: (state, action: PayloadAction<string>) => {
      state[action.payload] = { ...state[action.payload], enabled: false }
    },
  },
})

export const { enable, disable } = featureToggleSlice.actions
export const selectFeatures = (state: AppState) => state.features
export default featureToggleSlice.reducer
