import { AppState } from '@/src/store/store.tsx'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type UIState = {
  allowShowIntercom: boolean
}

const initialState: UIState = {
  allowShowIntercom: false,
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setAllowShowIntercom: (state, action: PayloadAction<boolean>) => {
      state.allowShowIntercom = action.payload
    },
  },
})

export const selectAllowShowIntercom = (state: AppState) =>
  state.ui.allowShowIntercom

export const { setAllowShowIntercom } = uiSlice.actions
export default uiSlice.reducer
