import { rootMiddleware, rootReducer } from '@/src/store/utils/storeHelper'
import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit'
import { createReduxEnhancer } from '@sentry/nextjs'
import { useDispatch } from 'react-redux'

const isDebug = process.env.NODE_ENV !== 'production'

const sentryReduxEnhancer = createReduxEnhancer({
  // #TODO
  // We need to clean password from this state before sending
  //  using stateTransformer
})

export const store = configureStore({
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer)
  },
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return rootMiddleware(getDefaultMiddleware) as ReturnType<
      typeof getDefaultMiddleware
    >
  },
  devTools: isDebug,
})

export const makeStore = () => store
type DispatchFunc = () => AppDispatch
export const useAppDispatch: DispatchFunc = useDispatch
export type AppDispatch = typeof store.dispatch
export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<AppStore['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>
