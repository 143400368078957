import { api } from '@/src/services'
import {
  DepositTransactionsType,
  GameTransactionsType,
  PromoTransactionsType,
  TransactionsParamsType,
  WithdrawalsTransactionsType,
} from '@/src/services/types/transactions.tsx'

const transactionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDepositTransactions: builder.query<
      DepositTransactionsType,
      TransactionsParamsType
    >({
      query: ({
        page = 1,
        endDate = '',
        startDate = '',
        status = '',
        totalEnabled = false,
      }) =>
        `/transactions/deposits?page=${page}&endDate=${endDate}&startDate=${startDate}&status=${status}&totalEnabled=${totalEnabled}`,
      providesTags: ['DepositTransactions'],
    }),
    getWithdrawalsTransactions: builder.query<
      WithdrawalsTransactionsType,
      TransactionsParamsType
    >({
      query: ({
        page = 1,
        endDate = '',
        startDate = '',
        status = '',
        totalEnabled = false,
      }) =>
        `/transactions/withdrawals?page=${page}&endDate=${endDate}&startDate=${startDate}&status=${status}&totalEnabled=${totalEnabled}`,
      providesTags: (result) =>
        result?.pageCount || 0 > 1
          ? [
              {
                type: 'WithdrawTransactions' as const,
                page: result?.currentPage,
              },
              'WithdrawTransactions',
            ]
          : ['WithdrawTransactions'],
    }),
    getGameTransactions: builder.query<
      GameTransactionsType,
      TransactionsParamsType
    >({
      query: ({
        page = 1,
        totalsEnabled,
        endDate = '',
        startDate = '',
        status = '',
        gameType = '',
      }) =>
        `/transactions/games/v2?page=${page}&totalsEnabled=${totalsEnabled}&endDate=${endDate}&startDate=${startDate}&status=${status}&gameType=${gameType} `,
      providesTags: ['GameTransactions'],
    }),
    getPromoTransactions: builder.query<
      PromoTransactionsType,
      TransactionsParamsType
    >({
      query: ({ page = 1, endDate = '', startDate = '', status = '' }) =>
        `/transactions/promo?page=${page}&endDate=${endDate}&startDate=${startDate}&status=${status}`,
      providesTags: ['PromoTransactions'],
    }),
  }),
  overrideExisting: process.env.NODE_ENV === 'development',
})

export const {
  useGetDepositTransactionsQuery,
  useGetWithdrawalsTransactionsQuery,
  useGetGameTransactionsQuery,
  useGetPromoTransactionsQuery,
} = transactionsApi

export const { getDepositTransactions } = transactionsApi.endpoints

export default transactionsApi
