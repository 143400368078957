import { api } from '@/src/services/index'
import {
  ContactsFormInput,
  LimitFormInput,
  LimitsFormattedType,
  LimitsType,
  PlayerType,
  RegistrationFormInput,
  StatusType,
} from '@/src/services/types'
import {
  InitRegistrationType,
  PaginatedActivities,
} from '@/src/services/types/games'
import { ApiError, RealityCheckType } from '@/src/services/types/limits'
import {
  MyAffiliatePayload,
  myAffiliateResponse,
} from '@/src/services/types/myAffiliate.tsx'
import {
  FreeSpinsType,
  GbgTokenType,
  IdentityVerificationType,
  PasswordChangeStatusType,
  StatusIdentityVerifiedType,
  StatusPlayerVerifiedType,
  UpdateUserType,
} from '@/src/services/types/payment'
import {
  DuplicationStatusType,
  DuplicationType,
  ForgotPasswordRequestType,
  IntercomTokenType,
  PaymentActivityType,
  PlayerBalanceType,
  PlayerSelfExclusionType,
  PlayerSurvey,
  PlayerSurveyRequestType,
  PlayerTimeOutType,
  ResetPasswordFormInput,
  SessionResponseType,
  TimeLimitFormInput,
  VerificationCodeStatusType,
  VerifyEmailStatusType,
} from '@/src/services/types/player.tsx'
import {
  ContactsFormType,
  DepositLimitRemove,
  LossLimitFormInput,
  LossLimitRemove,
  PasswordFormInput,
  RealityCheckFormInput,
} from '@/src/services/types/registrationForm.tsx'
import { DepositStatusType } from '@/src/services/types/transactions'
import {
  formatLimits,
  getFormData,
  getQueryData,
} from '@/src/services/utils/index.tsx'
import { setUser } from '@sentry/nextjs'

// END WEBSOCKET
const playerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPlayer: builder.query<PlayerType, void>({
      query: () => 'player',
      // NOTE: Hack to make date parsing work in safari
      // safari does not support parsing date format: 'YYYY-MM-DD'
      transformResponse: (props: PlayerType<string | null>) => {
        const { lastLoginTime, ...rest } = props
        // Think about setting user to null on logout
        setUser({ id: rest.userId })
        return {
          lastLoginTime: lastLoginTime
            ? new Date(`${lastLoginTime.replace(/\s/, 'T')}Z`).getTime()
            : null,
          ...rest,
        } as PlayerType
      },
      providesTags: ['User'],
    }),
    getAvailableSession: builder.query<SessionResponseType, void>({
      query: () => 'player/availableSession',
      providesTags: ['Token'],
    }),
    getPlayerActivity: builder.query<
      PaginatedActivities,
      { page?: number; status?: DepositStatusType | string }
    >({
      query: ({ page, status }) =>
        `player/activity?page=${page}&status=${status}`,
    }),
    getBalance: builder.query<PlayerBalanceType, void>({
      query: () => 'player/balance',
      providesTags: ['Balance'],
      keepUnusedDataFor: 5,
    }),
    getPaymentActivity: builder.query<PaymentActivityType, void>({
      query: () => 'player/activity',
      providesTags: ['PaymentActivity'],
    }),
    initRegistration: builder.query<InitRegistrationType, void>({
      query: () => 'player/initRegister',
    }),
    registerPlayer: builder.mutation<
      InitRegistrationType,
      RegistrationFormInput
    >({
      query: (formInput) => {
        return {
          url: `player/registerPlayer`,
          method: 'POST',
          body: getFormData(formInput),
        }
      },
      invalidatesTags: ['User'],
    }),
    updateUser: builder.mutation<UpdateUserType, ContactsFormType>({
      query: (formInput) => {
        const { queryData, ...body } = formInput

        const query = {
          sendEmail: !!queryData?.sendEmail,
        }

        return {
          url: `player/updateFields${getQueryData(query)}`,
          method: 'POST',
          body: getFormData(body as ContactsFormInput),
        }
      },
      invalidatesTags: ['User'],
    }),
    checkForDuplication: builder.mutation<
      DuplicationStatusType,
      DuplicationType
    >({
      query: ({ name, value }) => {
        name = name.toLowerCase()
        return {
          url: `player/${name}`,
          method: 'POST',
          body: getFormData({ [name]: value }),
        }
      },
    }),
    claimVoucher: builder.mutation<StatusType, number>({
      query: (id) => {
        return {
          url: `player/voucher/claim/${id}`,
          method: 'POST',
        }
      },
      invalidatesTags: ['Vouchers', 'Bonuses'],
    }),
    sendVerificationSMS: builder.mutation<VerificationCodeStatusType, void>({
      query: () => ({
        url: 'player/sendVerificationSms',
        method: 'GET',
      }),
    }),
    sendVerificationEmail: builder.mutation<VerificationCodeStatusType, void>({
      query: () => ({
        url: 'player/sendVerificationEmail',
        method: 'GET',
      }),
    }),
    sendWelcomeEmailPnp: builder.mutation<VerificationCodeStatusType, void>({
      query: () => ({
        url: 'player/sendWelcomeEmailPnp',
        method: 'GET',
      }),
    }),
    verifySms: builder.mutation<VerificationCodeStatusType, string>({
      query: (code) => ({
        url: 'player/verifySmsShortCode',
        method: 'POST',
        body: getFormData({ code }),
      }),
      invalidatesTags: ['User'],
    }),
    verifyEmail: builder.mutation<VerificationCodeStatusType, string>({
      query: (code) => ({
        url: 'player/verifyEmailShortCode',
        method: 'POST',
        body: getFormData({ code }),
      }),
      invalidatesTags: ['User'],
    }),
    validateEmail: builder.mutation<VerifyEmailStatusType, string>({
      query: (email) => ({
        url: 'player/email',
        method: 'POST',
        body: getFormData({ email }),
      }),
      invalidatesTags: ['User'],
    }),
    getLimits: builder.query<LimitsFormattedType, void>({
      query: () => 'player/limits',
      transformResponse: (response: LimitsType) => {
        return formatLimits(response)
      },
      providesTags: ['Limits'],
    }),
    updateDepositLimits: builder.mutation<
      LimitsType & ApiError,
      LimitFormInput
    >({
      query: (limits) => ({
        url: 'player/limit/deposit',
        method: 'POST',
        body: getFormData(limits),
      }),
      invalidatesTags: ['Limits'],
    }),
    removeDepositLimits: builder.mutation<
      LimitsType & ApiError,
      DepositLimitRemove
    >({
      query: (limits) => ({
        url: 'player/limit/deposit/remove',
        method: 'POST',
        body: getFormData(limits),
      }),
      invalidatesTags: ['Limits'],
    }),
    updateLossLimits: builder.mutation<
      LimitsType & ApiError,
      LossLimitFormInput
    >({
      query: (limits) => ({
        url: 'player/limit/v2/loss',
        method: 'POST',
        body: getFormData(limits),
      }),
      invalidatesTags: ['Limits'],
    }),
    removeLossLimits: builder.mutation<StatusType, LossLimitRemove>({
      query: (limits) => ({
        url: 'player/limit/v2/loss/remove',
        method: 'POST',
        body: getFormData(limits),
      }),
    }),
    updateSessionLimits: builder.mutation<StatusType, LimitFormInput>({
      query: (limits) => ({
        url: 'player/limit/periodTime',
        method: 'POST',
        body: getFormData(limits),
      }),
      invalidatesTags: ['Limits'],
    }),
    updateRealityCheck: builder.mutation<StatusType, RealityCheckFormInput>({
      query: (limit) => ({
        url: 'player/limit/realityCheck',
        method: 'POST',
        body: getFormData(limit),
      }),
      invalidatesTags: ['Limits', 'RealityCheck'],
    }),

    getPlayerIdentity: builder.mutation<
      StatusIdentityVerifiedType,
      IdentityVerificationType
    >({
      query: (props) => ({
        url: 'identity/finish',
        method: 'POST',
        body: getFormData(props),
      }),
      invalidatesTags: ['User', 'Balance'],
    }),
    getPlayerVerified: builder.mutation<StatusPlayerVerifiedType, void>({
      query: () => ({
        url: 'player/verify/info',
        method: 'POST',
        body: getFormData({}),
      }),
      invalidatesTags: ['User'],
    }),
    //confirm if right type
    getCashBackBonus: builder.query<FreeSpinsType, void>({
      query: () => '/player/bonuses/cashBack',
      providesTags: ['Bonuses'],
    }),
    getFreeSpinsBonus: builder.query<FreeSpinsType, void>({
      query: () => '/player/bonuses/freeSpins/v2',
      providesTags: ['Bonuses'],
    }),
    getBettingBonus: builder.query<FreeSpinsType, void>({
      query: () => '/player/bonuses/betting',
      providesTags: ['Bonuses'],
    }),
    cancelFreeSpinBonus: builder.mutation<
      StatusType,
      { freeSpinsBonusId: number }
    >({
      query: ({ freeSpinsBonusId }) => ({
        url: `/player/bonuses/freeSpins/cancel`,
        body: getFormData({ freeSpinsBonusId }),
        method: 'POST',
      }),
      invalidatesTags: ['Bonuses'],
    }),
    selfExclude: builder.mutation<StatusType, PlayerSelfExclusionType>({
      query: (limits) => ({
        url: 'player/close',
        method: 'POST',
        body: getFormData(limits),
      }),
      invalidatesTags: ['Balance'],
    }),
    timeOut: builder.mutation<StatusType, PlayerTimeOutType>({
      query: (limits) => {
        if (limits.period) {
          limits.period = `P${limits.period}`
        }

        return {
          url: 'player/status/timeout',
          method: 'POST',
          body: getFormData(limits),
        }
      },
    }),
    myAffiliate: builder.mutation<myAffiliateResponse, MyAffiliatePayload>({
      query: ({ myAffiliateId, myAffiliateToken }) => ({
        url: 'player/myAffiliate',
        method: 'POST',
        body: getFormData({ myAffiliateId, myAffiliateToken }),
      }),
    }),
    getRealityCheck: builder.query<RealityCheckType, void>({
      query: () => 'player/limit/v2/realityCheck',
      providesTags: ['RealityCheck'],
    }),
    resetRealityCheck: builder.mutation<LimitsFormattedType, void>({
      query: () => ({
        url: 'player/limit/realityCheck/reset',
        method: 'GET',
      }),
      invalidatesTags: ['Balance', 'RealityCheck'],
    }),
    getGbgToken: builder.query<GbgTokenType, void>({
      query: () => 'player/verify/gbg/token',
    }),
    updatePassword: builder.mutation<
      PasswordChangeStatusType,
      PasswordFormInput
    >({
      query: (props) => ({
        url: 'player/updatePassword',
        method: 'POST',
        body: getFormData(props),
      }),
    }),
    forgotPassword: builder.mutation<
      PasswordChangeStatusType,
      ForgotPasswordRequestType
    >({
      query: (props) => ({
        url: 'player/triggerForgotPassword',
        method: 'POST',
        body: getFormData({ ...props }),
      }),
    }),
    setTimeLimit: builder.mutation<LimitsFormattedType, TimeLimitFormInput>({
      query: (props) => ({
        url: 'player/limit/periodTime',
        method: 'POST',
        body: getFormData(props),
      }),
      transformResponse: (response: LimitsType) => formatLimits(response),
      invalidatesTags: ['Limits'],
    }),
    resetPassword: builder.mutation<StatusType, ResetPasswordFormInput>({
      query: (props) => ({
        url: 'player/modifyForgotPassword',
        method: 'POST',
        body: getFormData(props),
      }),
    }),
    survey: builder.mutation<PlayerSurvey, PlayerSurveyRequestType>({
      query: ({ surveyId, answer }) => ({
        url: `/player/survey/${surveyId}/answers?info=${answer}`,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    getIntercomToken: builder.query<IntercomTokenType, void>({
      query: () => 'player/intercom/token',
    }),
  }),
  overrideExisting: process.env.NODE_ENV === 'development',
})

export const {
  useGetGbgTokenQuery,
  useGetPlayerVerifiedMutation,
  useGetPlayerIdentityMutation,
  useGetAvailableSessionQuery,
  useGetPlayerActivityQuery,
  useGetPaymentActivityQuery,
  useGetPlayerQuery,
  useGetLimitsQuery,
  useClaimVoucherMutation,
  useUpdatePasswordMutation,
  useSendVerificationEmailMutation,
  useSendVerificationSMSMutation,
  useSendWelcomeEmailPnpMutation,
  useVerifyEmailMutation,
  useValidateEmailMutation,
  useVerifySmsMutation,
  useUpdateUserMutation,
  useCheckForDuplicationMutation,
  useUpdateDepositLimitsMutation,
  useRemoveDepositLimitsMutation,
  useRemoveLossLimitsMutation,
  useUpdateLossLimitsMutation,
  useUpdateSessionLimitsMutation,
  useUpdateRealityCheckMutation,
  useGetFreeSpinsBonusQuery,
  useGetCashBackBonusQuery,
  useGetBettingBonusQuery,
  useCancelFreeSpinBonusMutation,
  useSelfExcludeMutation,
  useTimeOutMutation,
  useMyAffiliateMutation,
  useGetRealityCheckQuery,
  useResetRealityCheckMutation,
  useRegisterPlayerMutation,
  useInitRegistrationQuery,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useSurveyMutation,
  useGetIntercomTokenQuery,
  useGetBalanceQuery,
  useSetTimeLimitMutation,
} = playerApi

export const {
  getPlayer,
  getPlayerActivity,
  getAvailableSession,
  getPaymentActivity,
  getBalance,
} = playerApi.endpoints

//SSR endpoints
export default playerApi
