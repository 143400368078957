import { AppState } from '@/src/store/store.tsx'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type RedirectState = {
  redirectTo: string | null
}

const initialState: RedirectState = {
  redirectTo: null,
}

export const redirectSlice = createSlice({
  name: 'redirect',
  initialState,
  reducers: {
    redirectTo: (state, action: PayloadAction<string>) => {
      state.redirectTo = action.payload
    },
    clearRedirect: (state) => {
      state.redirectTo = null
    },
  },
})

export const { redirectTo, clearRedirect } = redirectSlice.actions
export const selectRedirectTo = (state: AppState) => state.redirect.redirectTo
export default redirectSlice.reducer
