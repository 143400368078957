import { LoginType } from '@/src/services/types'
import { AppState } from '@/src/store/store.tsx'
import localStorageService, {
  TokenActionType,
} from '@/src/utils/localStorage.tsx'
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'

type SliceState = {
  isLoggedIn: boolean | undefined
  needsLogout: boolean | undefined
  user: LoginType | Record<string, never>
  token: string | null
  tokenType: TokenActionType
  needsAcceptTerms: string | null
  redirectUrl: string | null
}

const initialState: SliceState = {
  isLoggedIn: undefined, // Todo: JWT token expiry, currently undefined to prevent redirect to lobby
  needsLogout: false,
  token: localStorageService.getAuthToken(),
  tokenType: localStorageService.getAuthAction(),
  user: {},
  needsAcceptTerms: null,
  redirectUrl: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    isLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload
      if (state.isLoggedIn) {
        state.needsLogout = true
      } else if (state.needsLogout && state.isLoggedIn === false) {
        state.needsLogout = false
      }
    },
    logout: (state, action: PayloadAction<{ rg?: boolean } | undefined>) => {
      localStorageService.clearAuthData()
      state.user = {}
      state.isLoggedIn = false
      localStorageService.clearHideRGI()
      if (state.needsLogout && action?.payload?.rg) {
        state.redirectUrl = '/static/responsible-gambling'
      } else if (state.needsLogout && !state.redirectUrl) {
        state.redirectUrl = '/logged-out'
      }
    },
    setRedirectUrl: (state, action: PayloadAction<string | null>) => {
      // The first that gets written the first that gets read
      if (!state.redirectUrl || action.payload === null) {
        state.redirectUrl = action.payload
      }
    },
    prepareLogin: (
      state,
      action: PayloadAction<{ token: string; tokenType: TokenActionType }>,
    ) => {
      const { token, tokenType } = action.payload
      localStorageService.setAuthToken(token, tokenType)
      state.token = token
      state.tokenType = tokenType
    },
    setNeedsAcceptTerms: (state, action: PayloadAction<string | null>) => {
      state.needsAcceptTerms = action.payload
    },
  },
})

export const {
  isLoggedIn,
  prepareLogin,
  logout,
  setRedirectUrl,
  setNeedsAcceptTerms,
} = authSlice.actions
export const selectIsLoggedIn = (state: AppState) => state.auth.isLoggedIn
export const selectNeedsLogout = (state: AppState) => state.auth.needsLogout
const selectAuthToken = (state: AppState) => state.auth.token
const selectAuthTokenType = (state: AppState) => state.auth.tokenType
export const selectCredentials = createSelector(
  [selectAuthToken, selectAuthTokenType],
  (token, tokenType) => {
    return { token, tokenType }
  },
)
export const selectRedirectUrl = (state: AppState) => state.auth.redirectUrl

export default authSlice.reducer
